<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-account menu="datafeed"></submenu-account>

    <div class="submenu-content">
      <h2 class="pb-4">Datafeed Service</h2>
      
      <div v-if="error">
        <div class="alert alert-danger alert-dismissible fade show" role="alert">
          {{error}}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>
      <div v-if="message">
        <div class="alert alert-success alert-dismissible fade show" role="alert">
          {{message}}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>

      <div v-if="enrolled">
        <p>Click the button below to download the latest data feed CSV file. The file is updating hourly during Ma Labs' business hours. </p><p>Please contact your sales representative if you encounter any questions.</p>
        <button v-if="waiting == false" class="btn btn-primary  mt-3" @click="handleDownload">Download the latest Datafeed</button>
        <button v-if="waiting == true" class="btn btn-primary mt-3" disabled >
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Download the latest Datafeed      
        </button>
        <div class="mt-2 alert alert-secondary">
          <span class="badge bg-danger">Important Notice</span> Ma Labs makes every effort to stock inventory, but product availability is subject to change. Please confirm with your Ma Labs Account Manager for details.
Some Brands may not be eligible for international purchase and can only be sold to USA Domestic customers only. Please verify with your sales rep for further information.
        </div>
        
      </div>
      <div v-else>
        <p>
          To enroll in the Ma Labs’ Data Feed Service, please download the <a :href="documents[0]" download="DataFeed_agreement.pdf" target="blank">Datafeed Agreement</a> and email the filled form to webmaster@malabs.com. Our team will review your profile and send you a confirmation email within 3 business days after approval.
        </p>
      </div>
    
      
      <p class="mt-5">
        <div>Sample Data: <a :href="documents[3]" download="datafeed-sample-data-2021.csv" target="blank">MaLabs Datafeed Sample</a> (click to download)</div>
        <div>For more information, please refer to <a :href="documents[1]" download="Ma_Labs_Datafeed_Service_FAQ-2021.pdf" target="blank">MaLabs Datafeed Service FAQ</a> (click to download).</div>
      </p>
      
    </div> 
 

  </div>
</template>

<script>
import submenuAccount from '../../components/nav/submenuAccount.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { useStore } from 'vuex'
import { ref } from 'vue'
import { getAuthAPI,getAPI } from '../../utils/axios-api'

export default {
  name: 'Datafeed',
  components: { submenuAccount, BreadcrumbPage },
  setup() {
    const waiting = ref(false)
    const message = ref("")
    const error = ref("")

    const store = useStore()
    const user = ref(store.getters.user)
    const is_customer = ref(store.getters.isCustomer)
    const user_name = ref(store.getters.userName)
    const custno = ref(store.getters.custno)
    const user_email = ref(store.getters.userEmail)
    const enrolled = ref(false)
    const documents = ref([])

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "My Account", link: ""})
    breadcrumb_links.value.push({text: "Data Feed Service", link: "Datafeed"})

    if (is_customer) {
      getAuthAPI.get('/product/customer-service/status/' + custno.value + '/'+ user_email.value)
      .then(response => {
        enrolled.value = response.data.datafeed
      })
      .catch(err => {
          error.value = err.message
      })
    }


    // get static datafeed and api documents
    getAPI.get('/content/pages/65/')
      .then(response => {
        let feed = response.data.body
        for(let index in feed){
          documents.value.push("/"+feed[index].value)
        }
      })
      .catch(err => {
          error.value = err.message
      })

    //turn the responsed data from associated dictionary into an arry
    function processData(data) {
      //first row is titles
      var rows = [
               ['list_no', 
                'item_no', 
                'upc_code', 
                'manufacturer_no',
                'manufacturer',
                'category',
                'product_name',
                'price',
                'instant_rebate',
                'instant_rebate_no',
                'weight',
                'unit',
                'length',
                'width',
                'height',
                'package',
                'specorder',
                'is_domestic_only',
                'inventory'
                ]
      ]

      data.forEach(function(rowDict) {
        rows.push([rowDict.list_no,
                  rowDict.item_no,
                  rowDict.upc_code,
                  rowDict.manufacturer_no,
                  rowDict.manufacturer,
                  rowDict.category,
                  rowDict.product_name,
                  rowDict.price,
                  rowDict.instant_rebate,
                  rowDict.instant_rebate_item_no,
                  rowDict.weight,
                  rowDict.unit,
                  rowDict.length,
                  rowDict.width,
                  rowDict.height,
                  rowDict.package,
                  rowDict.specorder,
                  rowDict.is_domestic_only,
                  rowDict.inventory])

      })

      return rows
    }

    const handleDownload = () => {
      waiting.value = true
      getAuthAPI.get('/product/customer-service/datafeed/download/')
      .then(response => {
        waiting.value = false

        //datafeed data
        let data = response.data

        //process the data 
        let csvContent = ""
        const rows = processData(data)
        rows.forEach(function(rowArray) {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        //download
        var pom = document.createElement('a');
        var blob = new Blob([csvContent],{type: 'text/csv;charset=utf-8;'});
        var url = URL.createObjectURL(blob);
        pom.href = url;
        pom.setAttribute('download', 'datafeed.csv');
        pom.click();
  

      })
      .catch(err => {
          waiting.value = false
          error.value = err.message
      })
    }

    return {
      waiting,
      message,
      error,
      enrolled,
      handleDownload,
      documents,
      breadcrumb_links
    }
  }
}
</script>


<style scoped>
.red {color:red;}
</style>